import React from 'react'
import {Slider} from '../components/Slider'
import {Services} from './sub/Services'
import {About} from './sub/About'
import {Programs} from './sub/Programs'
import {Banner} from './sub/Banner'
import {BlogList} from '../components/Blog'
import { Helmet } from 'react-helmet-async';

export const Homepage = () => {
	return(
		<>
			<Helmet>
				<title>Kutaisi University official website | Study MBBS Georgia Kutaisi</title>
				<meta name="description" content="Discover the opportunity to study MBBS in Georgia at Kutaisi University. Explore our official website for more information on our world-class medical education." />
				<meta name="keywords" content="Kutaisi University Official Website , Kutaisi University , Study MBBS in Georgia , Study Georgia , Study MBBS in Georgia for Indian Students" />
				<link rel="canonical" href="https://europeanuniversity.in/" />
			</Helmet>

			<Slider />
			{/* <Services /> */}
			<About />
			<Programs />
			<Banner />
			{/* <div className="pt-35 md-pt-35 md-pb-70">
				<div className="container">
					<div className="sec-title text-center">
	                    <div className="sub-title primary">News Update </div>
	                    <h2 class="title mb-0">Latest News & Events</h2>
	                </div>
                </div>
				<BlogList />
			</div> */}
		</>
	)
}